import * as THREE from "three";
import { gsap } from "gsap";

import vs from "./shaders/vert.glsl";
import fs from "./shaders/frag.glsl";
import fs_dustcell5th from "./shaders/frag_dustcell5th.glsl";

export class Mesh {
    constructor(stage, id) {
        this.id = id || null;
        this.geometryParm = {
            width: 1,
            height: 1,
            widthSegments: 1,
            heightSegments: 1,
        };
        this.position = new THREE.Vector3(0, 0, 0);

        this.materialParam = {
            useWireframe: false,
        };

        //status
        this.texture = null; //後でロードされる
        this.geometry = null;
        this.material = null;
        this.mesh = null; // mesh
        this.stage = stage;

        this.windowWidth = 0;
        this.windowHeight = 0;

        this.nrw = window.innerWidth <= 834;

        //mouse
        this.mouse = new THREE.Vector2(0, 0);

        this.uniforms = {
            uPixelRatio: {
                type: "f",
                value: window.devicePixelRatio < 2 ? 1.5 : Math.min(2, window.devicePixelRatio),
            },
            uResolution: {
                type: "v2",
                value: null,
            },
            uTexture: {
                type: "t",
                value: null,
            },
            uTime: {
                type: "f",
                value: 0,
            },
            uNrwPower: {
                value: "f",
                value: this.nrw ? 0 : 1,
            },
            uMouse: {
                type: "v2",
                value: this.mouse,
            },
            uGlobalAlpha: {
                value: "f",
                value: this.nrw ? 0.25 : 0.24,
            },
            uIntroPower: {
                value: "f",
                value: 0,
            },
        };
    }

    init() {
        this.setWindowSize();
        this.setMesh();
        this.setClock();

        window.addEventListener("mousemove", (ev) => {
            if (!this.nrw) {
                this.onMouseMove(ev);
            }
        });
    }

    setClock() {
        this.clock = new THREE.Clock();
    }

    setWindowSize() {
        // スクリーンサイズ
        this.windowWidth = this.stage.getCVsize().width;
        this.windowHeight = this.stage.getCVsize().height;

        //スクリーンの解像度をセット
        this.uniforms.uResolution.value = new THREE.Vector2(this.windowWidth, this.windowHeight);
        //console.log("resize");
    }

    setMesh() {
        // ジオメトリーを生成
        this.geometry = new THREE.PlaneGeometry(this.geometryParm.width, this.geometryParm.height, this.geometryParm.widthSegments, this.geometryParm.heightSegments);

        this.material = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: this.id === "dustcell5th" ? fs_dustcell5th : fs,
            wireframe: this.materialParam.useWireframe,
            uniforms: this.uniforms,
            transparent: true,
        });

        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.stage.scene.add(this.mesh);
        this.setMeshScale(this.windowWidth, this.windowHeight);
        this.setPosition(0, 0, 0);

        gsap.to(this.uniforms.uIntroPower, { value: 1, duration: 1 });
    }

    /**
     * メッシュのサイズを変更する
     */
    setMeshScale(w, h) {
        this.mesh.scale.x = w;
        this.mesh.scale.y = h;
    }

    setPosition(x, y, z) {
        this.position.set(x, y, z);
    }

    onMouseMove(event) {
        gsap.to(this.mouse, {
            x: (event.clientX / window.innerWidth) * 2 - 1,
            y: -(event.clientY / window.innerHeight) * 2 + 1,
            duration: 1.0,
        });
    }

    onResize() {
        this.setWindowSize();
        this.setMeshScale(this.windowWidth, this.windowHeight);
        this.nrw = window.innerWidth <= 834;

        if (this.nrw) {
            this.uniforms.uNrwPower.value = this.nrw ? 0 : 1;
        }
    }

    onRaf(elapsed) {
        this.uniforms.uTime.value = elapsed;
    }

    destroy() {
        this.stage.scene && this.stage.scene.remove(this.mesh);
        this.geometry && this.geometry.dispose();
        this.material && this.material.dispose();
        this.mesh && this.mesh.material.dispose();
        this.mesh && this.mesh.geometry.dispose();
        this.texture && this.texture.dispose();
        //console.log("Mesh destroy");
    }
}
