const modal = () => {
    const openbuttons = [...document.querySelectorAll(".js-modal_open")];
    const closebuttons = [...document.querySelectorAll(".js-modal_close")];
    const modals = [...document.querySelectorAll(".js-modal")];

    const openFunc = (e, _tgtmodal) => {
        e.preventDefault();
        let tgtmodal = null;

        if (!_tgtmodal) {
            tgtmodal = document.querySelector(`.js-modal[data-modalid=${e.target.getAttribute("data-modalid")}]`);
        } else {
            tgtmodal = document.querySelector(`.js-modal[data-modalid=${_tgtmodal}]`);
        }

        tgtmodal && tgtmodal.setAttribute("data-active", "1");
        document.body.classList.add("-lock");
    };

    openbuttons.forEach((el) => {
        el.addEventListener("click", (e) => {
            openFunc(e);
        });
    });

    closebuttons.forEach((el) => {
        el.addEventListener("click", () => {
            modals.forEach((el) => el.setAttribute("data-active", "0"));
            document.body.classList.remove("-lock");
        });
    });
};

export { modal };
