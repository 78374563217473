import { gsap } from "gsap";
import { Utility } from "../../util/utility";

const cursor = () => {
    const cursorElm = document.querySelector(".js-cursor");
    const cursorableElements = [...document.querySelectorAll(".js-cursorable_elm")];

    if (!cursorElm) {
        return;
    }

    window.addEventListener("mousemove", (e) => {
        if (Utility.isSP()) return;
        const x_pos = -e.clientX + cursorElm.offsetWidth / 2;
        const y_pos = -e.clientY + cursorElm.offsetHeight / 2;
        gsap.to(cursorElm, { x: -x_pos, y: -y_pos, duration: 0.4 });
    });

    cursorableElements.forEach((elm) => {
        elm.addEventListener("mouseenter", () => {
            if (!Utility.isSP()) {
                cursorElm.setAttribute("data-active", "1");
            }
        });
        elm.addEventListener("mouseleave", () => {
            if (!Utility.isSP()) {
                cursorElm.setAttribute("data-active", "0");
            }
        });
    });
};

export { cursor };
